<template>
  <div class="container">
    <div class="page flex-col">
      <img @click="$router.go(-1)" style="width: 24px;height: 24px;cursor:pointer;margin-top: 20px" src="../../../assets/img/user/phone.svg" alt="">
      <div class="layer1 flex-col">
        <div class="block1 flex-col">
          <span class="info1">Felton會員服務協議</span>
          <span class="paragraph1">
            歡迎您使用Felton會員服務！
            <br />
            &nbsp;&nbsp;
            <br />
            《Felton會員服務協議》（以下亦稱“本協議”）由您（以下亦稱“會員”）和Felton進行締結，對雙方具有同等約束效力。&nbsp;Felton建議您仔細閱讀本協議的全部內容，在確保您已經理解、接受了全部條款的前提下，繼續使用Felton會員服務。
            <br />
            &nbsp;
            <br />
            如果您不同意本協議的任一或全部條款內容，請不要以確認形式（包括但不限於支付行為/接受贈與、或完成了成為會員的全部程序而在此過程中未向Felton提出關於本協議的任何異議）進行下一步操作或使用Felton會員服務。當您以確認形式進行下一步操作或使用Felton會員服務時，即表示您與Felton已達成協議關係，您自願接受本協議並遵守本協議項下的全部約定。
            <br />
            &nbsp;
            <br />
            Felton有權變更本協議內容，一旦本協議內容髮生變更的，Felton將在相應頁面、站內信或以其他合理方式進行通知，請您仔細閱讀。如果您不同意變更的內容的，您可以選擇停止使用會員服務。如您繼續使用會員服務的，則視為您已經同意變更的全部內容。更新後的協議自文首更新之日起生效。
            <br />
            &nbsp;
            <br />
            一、服務說明
            <br />
            1.&nbsp;【Felton會員】是指完成了成為會員的所有程序，且在遵守本協議的前提下，在會員時長有效期內享受Felton提供的會員服務的自然人。&nbsp;Felton會員包括永久SVIP會員、VIP會員等類型。&nbsp;VIP會員可付費升級為SVIP會員，具體升級規則以Felton在相關頁面的說明為準。請您根據您的需求，開通您所需的會員，進而享受相應的服務。
            <br />
            2.&nbsp;【Felton會員服務】是由Felton向您提供的專享的會員權益，具體權益內容說明請您參照本協議4.1的內容
            <br />
            3.&nbsp;【Felton會員服務規則】是Felton在Felton會員中心已經或未來不時發布的與會員服務相關的協議、公告、頁面說明、通知、FAQ等內容。
            <br />
            &nbsp;
            <br />
            二、服務獲取
            <br />
            1.&nbsp;Felton&nbsp;會員服務為收費服務，您可以通過支付相應的服務費用購買；同時，您亦可以通過營銷活動、接受禮品卡等Felton認可的途徑獲取服務。
            <br />
            2.您在獲取Felton會員服務時，應當遵守本協議約定，不侵犯第三方或Felton的合法權益。您不得自行（或協助他人）通過以下方式獲取會員服務：
            <br />
            2.1&nbsp;以商業性或其他非個人使用等目的；
            <br />
            2.2&nbsp;通過機器人軟件、蜘蛛軟件、爬蟲軟件等任何自動程序、腳本、軟件等方式；
            <br />
            2.3&nbsp;未經Felton允許通過借用、租用、售賣、分享、轉讓會員服務的方式；
            <br />
            2.4&nbsp;通過不正當手段或以違反誠實信用原則的方式（如利用規則漏洞、利用系統漏洞、濫用會員身份、黑色產業、投機等）；
            <br />
            2.5&nbsp;通過利用或破壞Felton&nbsp;會員服務規則的方式。
            <br />
            Felton在此聲明：任何未經Felton明示授權而售賣Felton會員資格的行為屬於非法銷售，Felton有權追究其責任。
            <br />
            &nbsp;
            <br />
            三、您的賬號
            <br />
            1.&nbsp;賬號獲得
            <br />
            在您的會員服務的有效期限內，您享有會員權益的Felton賬號即為您的Felton會員賬號（即：與您的會員服務綁定的Felton賬號，以下亦稱“&nbsp;賬號”或“會員賬號”）。
            <br />
            同時，Felton在此善意提醒您，您應當在遵守Felton賬號使用規則的前提下，使用您的會員賬號。&nbsp;Felton建議您查閱相關子協議、各類公告、頁面說明和規範流程、FAQ等以獲得更多關於賬號使用規則的內容。
            <br />
            2.&nbsp;登錄
            <br />
            Felton會員服務需要您登錄您的Felton會員賬號方可使用。
            <br />
            3.&nbsp;賬號管理及安全
            <br />
            3.1&nbsp;&nbsp;您應自行負責並妥善、正確地保管、使用、維護您的會員賬號和密碼，並對您的賬號和密碼採取必要和有效的保密措施。非Felton法定過錯導致的任何遺失、洩露、被篡改、被盜以及其他因保管、使用、維護不當而造成的損失，您應自行承擔。
            <br />
            3.2&nbsp;&nbsp;如果您發現有人未經授權使用了您的賬號或您的賬號存在其他異常情況導致無法正常登錄使用的，則您需要按照Felton官方公佈的賬號找回流程進行賬號找回。在找回過程中，Felton可能會要求您提供相應信息及/或證明資料，請確保您所提供的內容真實有效，否則將可能無法通過Felton的驗證而導致找回失敗。
            <br />
            3.3&nbsp;&nbsp;為保護賬號安全，防止賬號被盜等情況發生，Felton可能會不時或定期採用一種或多種方式對賬號使用者進行用戶身份驗證（如短信驗證、郵件認證等），如未成功通過驗證的，Felton有合理理由懷疑該賬號出現被盜等不安全情況，並視情節嚴重情況而決定是否中止向該賬號繼續提供會員服務及/或採取進一步措施。
            <br />
            3.4&nbsp;&nbsp;請您特別注意，您的會員賬號下的行為視為您本人的行為，您應對您的賬號下發生的活動或通過該賬號進行的活動負責。
            <br />
            4.&nbsp;信息查詢
            <br />
            您可以通過登錄Felton個人中心免費查詢您的賬號信息詳情，包括已開通的會員服務內容、服務期限、消費記錄等。
            <br />
            &nbsp;
            <br />
            四、會員權益及額外付費相關
            <br />
            1.&nbsp;&nbsp;會員權益
            <br />
            1.1&nbsp;SVIP權益：可開通8個交易對、專享黃金三角套利交易（支持UB\BB)、專享服務器、定制皮膚、定制週報、專屬客服、會員俱樂部、活動優先參與權、專屬會員福利
            <br />
            1.2&nbsp;VIP權益：可開通3個交易對、定制週報、專屬客服、會員俱樂部、活動優先參與權、專屬會員福利
            <br />
            2.&nbsp;活動參與
            <br />
            在會員服務期限內，您有權選擇參加由Felton組織的活動並享有由Felton提供的各項優惠。具體活動及優惠的內容及規則以Felton實際提供為準。
            <br />
            3.&nbsp;優惠政策
            <br />
            Felton可能會根據實際運營情況對不同階段已經購買且持續有效的會員服務給予延期、權益升級等方面的優惠，具體優惠政策以Felton在相關服務頁面通知的內容為準。
            <br />
            4.&nbsp;&nbsp;使用規範
            <br />
            您在獲取、使用Felton&nbsp;會員服務(包括參與活動)時，應遵循Felton官方渠道公佈的流程、平台規範等：
            <br />
            4.1不得通過未經Felton授權或公佈的途徑、方式、渠道非法獲取Felton&nbsp;會員服務（包括但不限於部分或全部會員權益、&nbsp;會員賬號）及/或參與活動獲取福利（包括但不限於會員權益、虛擬產品、實體商品、會員服務兌換碼，本條下同）；
            <br />
            4.2不得通過不正當手段或以違反誠實信用原則的方式（如利用規則漏洞、利用系統漏洞、濫用會員身份、黑色產業、投機等違背Felton提供服務/舉辦活動的初衷的方式）參與活動或者獲取福利。
            <br />
            若您違反上述約定，則Felton有權對您所獲得的福利作出刪除、取消、清零等處理，且有權終止向您提供服務，由此造成的全部損失由您自行承擔。
            <br />
            5.&nbsp;&nbsp;服務期限
            <br />
            5.1&nbsp;您的會員服務的服務期限以您自行選擇並支付相應會員費用對應的服務期限為準，自您成為會員之時起算，您可以通過登錄Felton會員中心免費查詢。該期限不因您未使用或對機器人的暫停等情況而延長。當會員服務期限到期後，Felton將停止繼續向您提供會員服務；但如您在服務期限到期前續費成功的，會員服務期限將在原服務期限的基礎上順延。
            <br />
            5.2&nbsp;SVIP降級後需要更換服務器，後台自動重啟。重啟期間造成的損失Felton不承擔責任。
            <br />
            5.3&nbsp;到期後保留1個月的熄滅狀態，熄滅狀態期間可續費，享受8折優惠，到期超過1個月後，熄滅狀態的圖標也沒有了，此時只能重新購買，沒有續費通道。
            <br />
            5.4&nbsp;不同級別的會員時長可以相累加，優先消耗高級別的會員時長。
            <br />
            5.5&nbsp;請您理解，因互聯網服務的特殊性，會員服務期限中包含Felton解決故障、服務器維修、調整、升級等或因第三方侵權處理所需用的合理時間，但Felton會盡可能將影響降至最低。
            <br />
            &nbsp;
            <br />
            五、收費及退訂
            <br />
            1.&nbsp;收費方式
            <br />
            Felton會員服務為收費服務，您可通過Felton實際支持的付費方式完成會員費用的支付，如我的資產扣費。請注意，資產充值時，因地址錯誤造成的損失，用戶需自行承擔責任。
            <br />
            2.&nbsp;費用退還
            <br />
            會員服務一經開通後不可退款（如因會員服務存在重大瑕疵導致您完全無法使用等Felton違約情形、本協議另有約定、必須退款的或Felton同意退款等除外）。
            <br />
            Felton在此特別提醒您，您在購買會員服務之前應仔細核對賬號信息、購買的服務內容、價格、服務期限等信息。
            <br />
            3.&nbsp;收費標準、方式的變更
            <br />
            會員服務的收費方式、收費標準由Felton根據公司的運營成本、運營策略等綜合考慮後獨立決定（調整包括但不限於促銷、漲價等），並在相關的產品服務宣傳及支付頁面向您展示；若您在購買和續費時，相關收費方式發生變化的，以Felton實際支持的收費方式為準；相關服務的價格發生了調整的，應以Felton平台上公示的現時有效的價格為準（但Felton與您另有約定的情形除外）。
            <br />
            六、您的行為規範和違約處理
            <br />
            1.&nbsp;在您使用Felton&nbsp;會員服務的過程中，不得存在以下行為：
            <br />
            1.1&nbsp;&nbsp;未經Felton明確授權，通過技術手段對服務內容、服務期限、消費金額、交易狀態等信息進行修改；
            <br />
            1.2&nbsp;&nbsp;將會員服務通過非Felton明確授權的方式（包括但不限於借用、租用、售賣、分享、轉讓）提供他人使用；
            <br />
            1.3&nbsp;&nbsp;未經Felton明確授權，將&nbsp;會員服務提供的視頻的全部或部分進行複制、下載、上傳、修改、編目排序、翻譯、發行、開發、轉讓、銷售、展示、傳播、合成、嵌套、鏈接、創作衍生作品、進行商業開發或推廣等；
            <br />
            1.4&nbsp;&nbsp;對Felton用於保護會員服務的任何安全措施技術進行破解、更改、反操作、篡改或其他破壞；
            <br />
            1.5&nbsp;&nbsp;未經Felton事先書面同意，刪除會員服務內容上的任何所有權或知識產權聲明或標籤；
            <br />
            1.6&nbsp;&nbsp;未經Felton明確授權，採用收費或免費的方式，在任何公開場合全部或部分展示會員服務內容（但如您的上述行為不構成侵權的除外）；
            <br />
            1.7&nbsp;&nbsp;其他未經Felton明示授權許可或違反本協議、侵犯第三方或Felton合法權益的行為。
            <br />
            2.&nbsp;&nbsp;您知悉並同意，如您存在任何違反本協議或有損Felton或/及其關聯公司的聲譽、利益的行為的，Felton有權採取以下一項或多項處理措施：
            <br />
            2.1&nbsp;&nbsp;如本協議對此行為有單獨條款約定處理方式的，按照該條款處理；
            <br />
            2.2&nbsp;&nbsp;採取一種或多種措施制止您的行為及行為產生的後果，如刪除/屏蔽相關鏈接或內容、限制/取消您的賬號/賬戶使用權限等；
            <br />
            2.3&nbsp;&nbsp;中斷或終止部分或全部會員服務，且您已交納的會員服務費用將不予退還且不獲得任何形式的補償/賠償；
            <br />
            2.4&nbsp;&nbsp;如您的行為使Felton或/及其關聯公司遭受任何損失的，您應當承擔全部損失賠償責任並在Felton要求的時限內完成費用支付。&nbsp;
            <br />
            &nbsp;
            <br />
            七、服務的中斷和終止&nbsp;
            <br />
            1.&nbsp;本服務的中斷或終止包括如下情況：
            <br />
            1.1&nbsp;&nbsp;您主動提出要求的；
            <br />
            1.2&nbsp;&nbsp;您存在任何違反國家法律法規或監管政策、違反本協議或有損Felton或/及其關聯公司的聲譽、利益的行為的；
            <br />
            1.3&nbsp;&nbsp;Felton根據法律法規、監管政策的規定或有權機關的要求；
            <br />
            1.4&nbsp;&nbsp;Felton為維護賬號與系統安全等緊急情況之需要；
            <br />
            1.5&nbsp;&nbsp;不可抗力（不可抗力是指：不能預見、不能避免且不能客服的客觀情況，包括國家法律、法規、政策及國家機關的命令及其他政府行為或者其它的諸如颱風、洪水、地震、海嘯、戰爭、罷工等）；
            <br />
            1.6&nbsp;&nbsp;其他Felton無法抗拒的情況。
            <br />
            2.&nbsp;當發生前述終止的情況時，Felton將根據法律法規的規定與您就本服務的中斷或終止事宜進行處理。
            <br />
            &nbsp;
            <br />
            八、您的個人信息保護
            <br />
            Felton深知個人信息對您的重要性，因此Felton非常重視保護您的個人信息，亦將您的個人信息以高度審慎的義務對待和處理。在您使用Felton&nbsp;會員服務的過程中，Felton將採用相關技術措施及其他安全措施來保護您的個人信息。
            <br />
            &nbsp;
            <br />
            九、通知&nbsp;
            <br />
            為便於您獲知與本協議和會員服務相關的信息，您同意Felton有權通過網頁公示、頁面提示、彈窗、消息通知、公眾號通知、站內信、您預留的聯繫方式（如手機短信、電子郵件等）等一種或多種方式進行通知，該通知自Felton發送之時視為已成功送達您。如多種通知方式並存的，則送達時間以上述方式中最早發送之時為準。
            <br />
            此類通知的內容或將對您產生重大有利或不利影響，請您務必確保聯繫方式為有效並請及時關注相應通知。
            <br />
            &nbsp;
            <br />
            十、聯繫Felton
            <br />
            如您對本協議或使用&nbsp;會員服務的過程中有任何問題（包括問題諮詢、投訴等），Felton專門為您提供了多種
            <p style="color: red;line-height: 20px;margin-top:10px;text-align: center;">
        特別提示：您閱讀本頁面即表示簽署確認本風險揭示書，表明您已經理解並願意自行承擔接受自動化策略交易的風險和損失。</p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "member_service_agreement"
}
</script>

<style scoped>
.container{width: 100%}
.page {
  z-index: 1;
  position: relative;
  width: 900px;
  margin: 0 auto;
}

.layer1 {
  z-index: 2;
  padding-bottom: 50px;
  background-color: rgba(255, 255, 255, 1);
  width: 900px;
  justify-content: flex-end;
  align-items: center;
}

.block1 {
  z-index: auto;
}

.info1 {
  z-index: 4;
  height: 33px;
  display: block;
  color: rgba(30, 35, 41, 1);
  font-size: 24px;
  font-family: PingFangTC-Medium;
  line-height: 33px;
  text-align: center;
  align-self: center;
  margin-top: 20px;
}

.paragraph1 {
  z-index: 3;
  display: inline-block;
  width: 900px;
  color: rgba(30, 35, 41, 1);
  font-size: 14px;
  font-family: PingFangTC-Regular;
  line-height: 24px;
  text-align: left;
  margin-top: 37px;
}
@media screen  and (max-width: 950px){
  .page {
    z-index: 1;
    position: relative;
    width: 90%;
  }
  .layer1 {
    z-index: 2;
    padding-bottom: 50px;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
  .paragraph1 {
    z-index: 3;
    display: inline-block;
    width: 100%;
    color: rgba(30, 35, 41, 1);
    font-size: 14px;
    font-family: PingFangTC-Regular;
    line-height: 24px;
    text-align: left;
    margin-top: 37px;
  }
}
</style>